import { NgClass } from '@angular/common';
import { Component, booleanAttribute, numberAttribute, input } from '@angular/core';

@Component({
  selector: 'app-linear-progress-bar',
  templateUrl: './linear-progress-bar.component.html',
  styleUrls: ['./linear-progress-bar.component.scss'],
  standalone: true,
  imports: [NgClass],
})
export class LinearProgressBarComponent {
  label = input<string | undefined>(undefined);
  showValue = input(false, { transform: booleanAttribute });
  value = input(0, { transform: numberAttribute });
  size = input<'small' | 'medium' | 'large'>('small');
  slot = input<'left' | 'right' | 'center'>('left');

  constructor() {}
}
