import { Component, OnInit } from '@angular/core';
import { Toast } from 'ngx-toastr';
import { LinkComponent } from '../link/link.component';
import { IconComponent } from "../icon/icon.component";

interface CustomPayload {
  firstActionText?: string;
  firstActionFunction(): any;
  secondActionText?: string;
  secondActionFunction(): any;
}

@Component({
  selector: 'app-toast',
  templateUrl: './toast.component.html',
  styleUrls: ['./toast.component.scss'],
  standalone: true,
  preserveWhitespaces: false,
  imports: [LinkComponent, IconComponent],
})
export class ToastComponent extends Toast implements OnInit {
  //* https://www.npmjs.com/package/ngx-toastr
  payload!: CustomPayload;
  ngOnInit(): void {
    this.payload = this.options?.payload;
  }

  firstActionFunction(ev: Event) {
    ev.stopPropagation();
    this.payload.firstActionFunction();
  }

  secondActionFunction(ev: Event) {
    ev.stopPropagation();
    this.payload.secondActionFunction();
  }
}
