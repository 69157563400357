import { Routes } from '@angular/router';
import { authenticationRoutes } from './pages/authentication/authentication.routes';
import { tabsRoutes } from './tabs/tabs.routes';

export const routes: Routes = [
  ...tabsRoutes,
  ...authenticationRoutes,
  //todo: re-enable implementing register
  // {
  //   path: 'register',
  //   loadComponent: () => import('./pages/register/register.page').then((m) => m.RegisterPage),
  // },

  //! Must be at the end !
  {
    path: '**',
    redirectTo: '',
    pathMatch: 'full',
  },
];
