<div class="flex nowrap justify-between">

  <div class="col-9">
    <div class="body-bold-2 text-brand-700">
      {{title}}
    </div>

    <div class="body-regular-2 text-brand-700">
      {{message}}
    </div>

    @if (payload) {
      <!-- Actions -->
      <div class="mt-3 text-brand-500 flex">

        @if (payload.firstActionText) {
          <div class="mr-4">
            <app-link size="medium" (click)="firstActionFunction($event)" tappable>{{payload.firstActionText}}</app-link>
          </div>
        }

        @if (payload.secondActionText) {
          <app-link size="medium" (click)="secondActionFunction($event)" tappable>{{payload.secondActionText}}</app-link>
        }

      </div>
    }
  </div>

  @if (options.closeButton) {
    <app-icon class="text-brand-700" (click)="remove()" tappable name="X" size="24"></app-icon>
  }
</div>
