import { booleanAttribute, Component, computed, input, output } from "@angular/core";
import {NgClass} from '@angular/common';
import {IconNames} from '../../../lucide-icon';
import { IconComponent } from "../icon/icon.component";

@Component({
  selector: 'app-button',
  templateUrl: './button.component.html',
  standalone: true,
  styleUrls: ['./button.component.scss'],
  imports: [NgClass, IconComponent],
})
export class ButtonComponent {
  size = input<'small' | 'medium' | 'large'>('medium');
  variant = input<'primary' | 'secondary' | 'tertiary' | 'conversion' | 'ghost' | 'delete'>('primary');
  slot = input<'left' | 'right' | 'icon-only' | undefined>(undefined);
  iconName = input<IconNames | undefined>(undefined);
  disabled = input(false, { transform: booleanAttribute });
  block = input(false, { transform: booleanAttribute });

  // Overwrite click ouput in order to cancel click in disabled mode
  click = output<Event>();

  protected iconSize = computed<number>(() => {
    if (this.slot() === 'icon-only') {
      return this.size() === 'large' ? 32 : this.size() === 'small' ? 20 : 24;
    } else {
      return this.size() === 'large' ? 24 : this.size() === 'small' ? 16 : 20;
    }
  });
  constructor() {
  }

  catchClick(event: Event) {
    event.stopPropagation();
    event.preventDefault();
    if (!this.disabled()) {
      this.click.emit(event);
    }
  }

}
