import {booleanAttribute, Component, input, OnInit, signal, ChangeDetectionStrategy} from '@angular/core';
import {NgClass} from "@angular/common";
import { IconComponent } from "../icon/icon.component";

@Component({
  selector: 'app-link',
  templateUrl: './link.component.html',
  styleUrls: ['./link.component.scss'],
  standalone: true,
  imports: [NgClass, IconComponent],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LinkComponent implements OnInit {
  size = input<'small' | 'medium' | 'large'>('medium');
  withIcon = input(false, { transform: booleanAttribute });
  visited = signal(false);

  // TODO : output or href

  constructor() {
  }

  ngOnInit() {}

}
