import { booleanAttribute, Component, input, InputSignal, OnInit } from '@angular/core';
import { IconNames } from '../../../lucide-icon';
import { IconComponent } from '../icon/icon.component';
import { NgClass } from '@angular/common';

@Component({
  selector: 'app-side-bar-item',
  templateUrl: './side-bar-item.component.html',
  styleUrls: ['./side-bar-item.component.scss'],
  standalone: true,
  imports: [IconComponent, NgClass],
})
export class SideBarItemComponent implements OnInit {
  iconName: InputSignal<IconNames> = input.required();
  selected = input(false, { transform: booleanAttribute });
  label: InputSignal<string> = input.required();
  badge: InputSignal<number | undefined> = input<number | undefined>(undefined);
  state: InputSignal<'collapsed' | 'expanded'> = input<'collapsed' | 'expanded'>('collapsed');

  constructor() {}

  ngOnInit() {}
}
