import {effect, Injectable, signal, WritableSignal} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {environment} from "../../../environments/environment";
import {mergeMap, Observable} from "rxjs";
import {Preferences} from "@capacitor/preferences";
import {OneSignalService} from "../../../services/one-signal/one-signal.service";
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {

  public  hasAccessToken: WritableSignal<boolean> = signal<boolean>(false);
  private accessToken: WritableSignal<string | undefined> = signal(undefined);
  private refreshToken: WritableSignal<string | undefined> = signal(undefined);

  constructor(private http: HttpClient,
              private oneSignalService: OneSignalService,
              private router: Router) {
    effect(async () => {
      if (this.hasAccessToken()) {
        try {
          const { userId } = this.getUserIdFromAccessToken(this.accessToken() as string);
          await this.oneSignalService.login(userId);
        } catch (error) {
          console.error("Unable to get userId from accessToken");
        }
      }
    });
  }

  loginWithProvider(provider: 'mail' | 'facebook' | 'google' |'apple', body: { email: string, password: string } | { accessToken: string } | { identityToken: string }) {
    let url: string = environment.apiUrl + `/session/${provider}`;
    return this.http.post<{ accessToken: string, refreshToken: string }>(url, body).pipe(mergeMap( async ({ accessToken, refreshToken }) => {
      await this.setAccessToken(accessToken);
      await this.setRefreshToken(refreshToken);
      return { accessToken, refreshToken };
    }));
  }


  async setAccessToken(accessToken: string) {
    await Preferences.set({ key: "accessToken", value: accessToken });
    this.accessToken.set(accessToken);
    this.hasAccessToken.set(true);
  }

  async setRefreshToken(refreshToken: string) {
    await Preferences.set({ key: "refreshToken", value: refreshToken });
    this.refreshToken.set(refreshToken);
  }

  refreshSessionToken(): Observable<{ accessToken: string }> {
    const url: string = environment.apiUrl + '/session/refresh';
    const body: { refreshToken: string }  = { refreshToken: this.refreshToken() as string };

    return this.http.post<{ accessToken: string }>(url, body).pipe(mergeMap( async ({ accessToken }) => {
      await this.setAccessToken(accessToken);
      return { accessToken };
    }));
  }

  getAccessToken() {
    return this.accessToken();
  }

  async clearTokens() {
    this.hasAccessToken.set(false);
    this.accessToken.set(undefined);
    this.refreshToken.set(undefined);
    await this.oneSignalService.logout();
    await Promise.all([
      Preferences.remove({ key: "accessToken" }),
      Preferences.remove({ key: "refreshToken" }),
    ]);
  }

  public async checkAuthentication(): Promise<boolean> {
    if (this.hasAccessToken())
      return true;

    // LOAD ACCESS_TOKEN & REFRESH TOKEN
    if (this.accessToken() === undefined) {
      const refreshToken = await Preferences.get({ key: "refreshToken"});
      if (refreshToken?.value)
        this.refreshToken.set(refreshToken.value);

      const token = await Preferences.get({ key: "accessToken"});
      if (token?.value) {
        this.accessToken.set(token.value);
        this.hasAccessToken.set(true);
        return true;
      }
    }



    return false;
  }

  public logout(): Observable<void> {
    const url: string = environment.apiUrl + '/session/logout';
    const body: { refreshToken: string }  = { refreshToken: this.refreshToken() as string };

    return this.http.post<void>(url, body).pipe(mergeMap( async () => {
      await this.clearTokens();
      this.router.navigate(['/login']).then(() => {
        window.location.reload();
      });
    }));
  }

  public getRefreshToken() {
    return this.refreshToken();
  }

  public getUserIdFromAccessToken(accessToken: string): {userId: string } {
    if (accessToken) {
      // @ts-ignore
      const sessionInfo = JSON.parse(atob(accessToken.split('.')[1]));
      return { userId: sessionInfo.userId };
    }
    return { userId: "" };
  }

  resetPassword(email: string) {
    const url = environment.apiUrl + `/users/reset-password`;
    return this.http.post(url, {email});
  }

  confirmNewPassword(newPassword: string, verifPassword: string, token: string) {
    const url = environment.apiUrl + `/users/reset-password/validate`;
    return this.http.post(url, {newPassword, verifPassword, token});
  }

  verifyResetPasswordToken(token: string) {
    const url = environment.apiUrl + `/users/reset-password/verifyToken`;
    return this.http.post(url, {token});
  }

}
