import { Component, input, InputSignal, OnInit, WritableSignal } from "@angular/core";
import { IonicModule } from "@ionic/angular";
import { LinearProgressBarComponent } from "../archimed/linear-progress-bar/linear-progress-bar.component";
import { SpinnerComponent } from "../archimed/spinner/spinner.component";

@Component({
  selector: 'app-custom-upload',
  templateUrl: './custom-upload.component.html',
  styleUrls: ['./custom-upload.component.scss'],
  standalone: true,
  imports: [IonicModule, LinearProgressBarComponent, SpinnerComponent],
})
export class CustomUploadComponent implements OnInit {
  progress: InputSignal<WritableSignal<number>> = input.required<WritableSignal<number>>();

  constructor() {}

  ngOnInit() {}
}
