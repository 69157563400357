import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})
export class ErrorService {

  constructor(
    private toast: ToastrService
  ) { }

  handleError(error?: {error: {code: string}, status: number | string}, customTitle = 'Une erreur est survenue') {
    let errorMessage = 'Merci de réessayer plus tard';

    if (error) {
      switch (error.error.code) {
        case 'end-date-ealier-than-start-date':
          errorMessage = 'La date de fin doit être postérieure à la date de début.';
          break;

        case 'user-cannot-update-his-own-permissions':
          errorMessage = 'Vous ne pouvez pas modifier vos propres permissions';
          break;

        case 'practitioner-must-have-at-least-one-profession':
          errorMessage = 'Un practicien doit avoir une profession';
          break;

        case 'user-not-found':
          errorMessage = 'Utilisateur non trouvé';
          break;

        case 'user-does-not-have-all-required-permissions':
        case 'structure-entity-permission-missing':
        case 'practitioner-entity-permission-missing':
          errorMessage = 'Vous n\'avez pas les permissions requises';
          break;

        case 'Unauthorized':
          errorMessage = 'Action non autorisée';
          break;

        case 'invalid-token':
          errorMessage = 'La demande a expirée';
          break;

      }
    }
    if (errorMessage) {
      this.toast.error(errorMessage, customTitle);
    }
  }
}
