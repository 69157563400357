import { Routes } from '@angular/router';

export const formationRoutes: Routes = [
  {
    path: 'formations',
    children: [
      {
        path: 'home',
        title: 'Formations',
        loadComponent: () => import('./home-formations/home-formations.page').then( m => m.HomeFormationsPage)
      },
      {
        path: 'my-formations',
        title: 'Mes formations',
        loadComponent: () => import('./my-formations/my-formations.page').then( m => m.MyFormationsPage)
      },
      {
        path: 'contact-formation',
        title: 'Contact formations',
        loadComponent: () => import('./contact-formation/contact-formation.page').then( m => m.ContactFormationPage)
      },
      {
        path: '**',
        redirectTo: 'home',
        pathMatch: 'full',
      },
    ],
  }

];
