<div
  class="flex items-center rounded-200 p-3 text-brand-700 hover:cursor-pointer hover:bg-neutral-200"
  [ngClass]="{
    'bg-brand-0': selected(),
    'bg-white': !selected(),
  }"
>
  <app-icon [name]="iconName()" [size]="24"></app-icon>
  @if (state() === "expanded") {
    <span
      class="line-clamp-1 pl-4"
      [ngClass]="{
        'body-bold-2': selected(),
        'body-regular-2': !selected(),
      }"
      >{{ label() }}</span
    >
  }
</div>
