import {Component, signal, WritableSignal} from '@angular/core';
import {IonApp, IonMenu, IonRouterOutlet, IonSplitPane} from '@ionic/angular/standalone';
import { Platform } from '@ionic/angular';
import {environment} from "../environments/environment";
import * as Sentry from '@sentry/capacitor';
import * as SentryAngular from '@sentry/angular-ivy';
import {FacebookLoginService} from "../services/facebook-login/facebook-login.service";
import {GoogleAuthService} from "../services/google-auth/google-auth.service";
import { NavigationStart, Router, Event, ResolveEnd, RouterLink } from "@angular/router";
import {ButtonComponent} from "../components/archimed/button/button.component";
import {SideBarItemComponent} from "../components/archimed/side-bar-item/side-bar-item.component";
import {IconNames} from "../lucide-icon";
import {AuthenticationService} from "./pages/authentication/authentication.service";
import {OneSignalService} from "../services/one-signal/one-signal.service";
import {BranchIoService} from "../services/branch-io/branch-io.service";
import { GoogleMapsService } from "../services/google-maps/google-maps.service";
import { FaviconService } from "../services/favicon/favicon.service";
import { NgClass, NgOptimizedImage } from "@angular/common";
import { animate, state, style, transition, trigger } from "@angular/animations";

Sentry.init({
    dsn: environment.sentryDSN,
    environment: environment.environmentName,
    enabled: environment.environmentName !== 'development',
    integrations: [
      new SentryAngular.BrowserTracing({
        // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
        tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
        routingInstrumentation: SentryAngular.routingInstrumentation,
      }),
      new SentryAngular.Replay({
        maskAllText: false,
        blockAllMedia: false,
      }),
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  },
  // Forward the init method from @sentry/angular-ivy
  SentryAngular.init
);

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrl: 'app.component.scss',
  standalone: true,
  imports: [IonApp, IonSplitPane, IonMenu, ButtonComponent, SideBarItemComponent, IonRouterOutlet, NgOptimizedImage, NgClass, RouterLink],
  animations: [
    trigger('menuWidth', [state('collapsed', style({ width: '88px' })), state('expanded', style({ width: '288px' })), transition('collapsed <=> expanded', [animate('0.3s ease-in-out')])]),
    trigger('imageState', [state('collapsed', style({ opacity: 0, transform: 'scale(0.8)' })), state('expanded', style({ opacity: 1, transform: 'scale(1)' })), transition('collapsed <=> expanded', [animate('0.3s ease-in-out')])]),
  ],
})
export class AppComponent {
  disabledMenu: WritableSignal<boolean> = signal(false);
  protected isMenuHover: WritableSignal<boolean> = signal(false);
  protected currentRootUrl: WritableSignal<string> = signal('');
  private disabledMenuUrls = ['/login', '/register', '/get-started', '/reset-password', '/forget-password'];

  menuItemsTop: { label: string; iconName: IconNames; action: () => void; rootUrl: string[] }[] = [
    {
      label: 'Accueil',
      iconName: 'Home',
      action: () => {
        this.router.navigate(['/home']);
      },
      rootUrl: ['home'],
    },
    {
      label: 'Emplois',
      iconName: 'Briefcase',
      action: () => {
        this.router.navigate(['/job']);
      },
      rootUrl: ['job'],
    },
    {
      label: 'Formations',
      iconName: 'GraduationCap',
      action: () => {
        this.router.navigate(['/formations']);
      },
      rootUrl: ['formations'],
    },
    // {
    //   label: 'Communauté',
    //   iconName: 'Users',
    //   action: () => {
    //     this.router.navigate(['/community']);
    //   },
    //   rootUrl: ['community'],
    // },
    {
      label: 'Recrutements',
      iconName: 'UserPlus',
      action: () => {
        this.router.navigate(['/adrien']);
      },
      rootUrl: ['adrien'],
    },
  ];

  menuItemsBottom: { label: string; iconName: IconNames; action: () => void; rootUrl?: string[] }[] = [
    {
      label: 'Messages',
      iconName: 'MessagesSquare',
      action: () => {
        this.router.navigate(['/discussions']);
      },
      rootUrl: ['discussions'],
    },
    {
      label: 'Notifications',
      iconName: 'BellRing',
      action: () => {
        this.router.navigate(['/notifications']);
      },
      rootUrl: ['notifications'],
    },
    {
      label: 'Profil',
      iconName: 'User',
      action: () => {
        this.router.navigate(['/profile']);
      },
      rootUrl: ['profile', 'practitioners'],
    },
    {
      label: 'Me déconnecter',
      iconName: 'LogOut',
      action: () => {
        this.logout();
      },
    },
  ];

  constructor(
    private router: Router,
    private platform: Platform,
    private authenticationService: AuthenticationService,
    private facebookLoginService: FacebookLoginService,
    private branchIoService: BranchIoService,
    private googleMapsLoader: GoogleMapsService,
    private oneSignalService: OneSignalService,
    private googleAuthService: GoogleAuthService,
    private faviconService: FaviconService,
  ) {
    this.subscribeRouterEvents();
    this.initializeApp();
    this.oneSignalService.initOneSignal();
  }

  private initializeApp(): void {
    this.platform.ready().then(async () => {
      // Branch (deep links)
      this.branchIoService.initDeepLinks();

      // Facebook Login
      this.facebookLoginService.initialize();

      // Google Auth
      this.googleAuthService.initialize();

      // Load Google Maps Script
      try {
        await this.googleMapsLoader.loadLibraries(['places']);
        console.info('Google Maps loaded successfully');
      } catch (error) {
        console.error('Unable to load Google Maps');
        console.error(error);
      }

      // Change favicon
      this.faviconService.initFaviconService();
    });
  }

  private subscribeRouterEvents() {
    this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationStart) {
        this.disabledMenu.set(this.disabledMenuUrls.includes(event.url.split('?')[0]));
      }

      if (event instanceof ResolveEnd) {
        this.currentRootUrl.set(event.urlAfterRedirects.split('/')[1]);
      }
    });
  }

  private logout() {
    this.authenticationService.logout().subscribe();
  }
}
