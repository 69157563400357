import { Injectable, signal } from '@angular/core';
import { environment } from '../../environments/environment';
import { Address } from '@appines/appines_types';
import { v4 as uuidv4 } from 'uuid';
import { HttpClient } from '@angular/common/http';
import { map, Observable } from 'rxjs';
import AutocompleteSuggestion = google.maps.places.AutocompleteSuggestion;
import AutocompleteSessionToken = google.maps.places.AutocompleteSessionToken;

@Injectable({
  providedIn: 'root',
})
export class GoogleMapsService {
  private static loaded = false;
  private static loadPromise: Promise<void>;
  private sessionToken = signal<AutocompleteSessionToken | any>(undefined);

  constructor(private http: HttpClient) {}

  public loadLibraries(libraries: string[] = []): Promise<void> {
    if (GoogleMapsService.loaded) {
      return GoogleMapsService.loadPromise;
    }

    // Initialisation de la promesse de chargement
    GoogleMapsService.loadPromise = new Promise((resolve, reject) => {
      // Création de l'élément script
      const script = document.createElement('script');
      script.async = true;
      script.defer = true;

      // Création de l'URL des paramètres
      const params = new URLSearchParams();
      params.set('key', environment.googlePlacesAPIKey);
      params.set('v', 'weekly');

      if (libraries.length > 0) {
        params.set('libraries', libraries.join(','));
      }

      // Définir le callback pour quand la librairie est chargée
      const callbackName = `gmapsCallback_${Date.now()}`;
      (window as any)[callbackName] = () => {
        GoogleMapsService.loaded = true;
        resolve();
      };

      params.set('callback', callbackName);

      // Définir l'URL du script
      script.src = `https://maps.googleapis.com/maps/api/js?${params.toString()}`;

      // Gestion des erreurs
      script.onerror = () => {
        reject(new Error('Google Maps JavaScript API could not load.'));
      };

      // Ajouter le script au document
      document.head.appendChild(script);
    });

    return GoogleMapsService.loadPromise;
  }

  searchPlace(value: any): Observable<AutocompleteSuggestion[]> {
    // Create a session token.
    // Session token must be a UUID V4
    // https://developers.google.com/maps/documentation/places/web-service/place-session-tokens?hl=fr

    // Use the same session token until user select one suggestion
    // Is to be billed only for 1 request, see : https://developers.google.com/maps/documentation/javascript/session-pricing?hl=fr
    if (!this.sessionToken()) {
      this.sessionToken.set(uuidv4());
    }

    const url: string = environment.apiUrl + `/geocoding/search/places?input=${value}&session=${this.sessionToken()}`;

    return this.http.get<{ suggestions: AutocompleteSuggestion[] }>(url).pipe(
      map((res) => {
        return res.suggestions;
      }),
    );
  }

  getPlaceDetails(placeId: string): Observable<{ name: string; address: Address; placeId: string }> {
    const url: string = environment.apiUrl + `/geocoding/places/details?placeId=${placeId}&session=${this.sessionToken()}`;

    return this.http.get<any>(url).pipe(
      map((res) => {
        this.sessionToken.set(undefined);
        return res;
      }),
    );
  }
}
