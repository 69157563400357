<ion-tabs (ionTabsWillChange)="catchTabChange($event)">
  <ion-tab-bar slot="bottom" class="desktop:hidden pt-4">


    @for (item of tabItems; track item.label; let i = $index) {
      <ion-tab-button [tab]="item.rootUrl">

        <app-icon [name]="item.iconName" size="20" strokeWidth="1.5"></app-icon>

        <ion-label class="mt-2" [ngClass]="{
        'caption-bold-1': activeTab() === item.rootUrl,
        'caption-regular-1': activeTab() !== item.rootUrl
        }">
          {{ item.label }}
        </ion-label>

      </ion-tab-button>

      @if (i === 1) {
        <div class="center-button px-3">
          <app-button variant="conversion" slot="icon-only" iconName="Plus"></app-button>
        </div>
      }

    }

  </ion-tab-bar>
</ion-tabs>
