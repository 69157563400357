import { booleanAttribute, ChangeDetectionStrategy, Component, effect, input, numberAttribute, OnInit, signal } from '@angular/core';
import { LucideAngularModule } from 'lucide-angular';
import { IconNames } from '../../../lucide-icon';

@Component({
  selector: 'app-icon',
  templateUrl: './icon.component.html',
  styleUrls: ['./icon.component.scss'],
  standalone: true,
  imports: [LucideAngularModule],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IconComponent implements OnInit {
  name = input.required<IconNames>();
  size = input(24, { transform: numberAttribute });
  strokeWidth = input(2, { transform: numberAttribute });
  absoluteStrokeWidth = input(false, { transform: booleanAttribute });

  // Due to an issue with lucide-icon, dynamic sizing is not working.
  // We need to alternate rendering using this variable to handle size changes.
  protected alternativeRender = signal<boolean>(true);

  constructor() {
    effect(
      () => {
        // this var is use to trigger change & update alternativeRender
        // @ts-ignore
        const uselessVar = `${this.name()}${this.size()}${this.strokeWidth()}${this.absoluteStrokeWidth()}`;
        this.alternativeRender.update((value) => !value);
      },
      { allowSignalWrites: true },
    );
  }

  ngOnInit() {}
}
