import { NgClass } from '@angular/common';
import { booleanAttribute, Component, input, OnInit } from '@angular/core';

@Component({
  selector: 'app-spinner',
  templateUrl: './spinner.component.html',
  styleUrls: ['./spinner.component.scss'],
  standalone: true,
  imports: [
    NgClass,
  ]
})
export class SpinnerComponent  implements OnInit {
  size = input<'small' | 'medium' | 'large'>('small');
  disabled = input(false, {transform: booleanAttribute});
  constructor() { }

  ngOnInit() {

  }


}
