import {
  AlertCircle,
  AlertTriangle,
  Eye,
  EyeOff,
  Banana,
  Bell,
  CheckCircle2,
  Hourglass,
  Info,
  Plus,
  X,
  Check,
  Bookmark,
  Sparkles,
  Star,
  ExternalLink,
  ChevronRight,
  ChevronDown,
  Heart,
  MapPin,
  Clock,
  ChevronLeft,
  Share,
  User,
  Minus,
  Search,
  Home,
  Briefcase,
  GraduationCap,
  Users,
  UserPlus,
  UserCircle,
  LogOut,
  MessagesSquare,
  LifeBuoy,
  HelpCircle,
  KeyRound,
  BellRing,
  Mail,
  Newspaper,
  ShieldCheck,
  ChevronUp,
  FileText,
  Settings,
  Building2,
  Send,
  QrCode,
  Trash2,
  Edit,
  Lock,
  SquarePen,
  CalendarDays,
  Cross,
  Compass,
} from 'lucide-angular';
export const icons = {
  Banana,
  Plus,
  Minus,
  Bell,
  Eye,
  EyeOff,
  X,
  Hourglass,
  Info,
  AlertCircle,
  CheckCircle2,
  AlertTriangle,
  Bookmark,
  Sparkles,
  Star,
  Check,
  ExternalLink,
  ChevronUp,
  ChevronRight,
  ChevronDown,
  ChevronLeft,
  MapPin,
  Clock,
  Heart,
  Share,
  User,
  Search,
  Home,
  Briefcase,
  Users,
  GraduationCap,
  UserPlus,
  MessagesSquare,
  UserCircle,
  LogOut,
  LifeBuoy,
  HelpCircle,
  KeyRound,
  BellRing,
  Mail,
  Newspaper,
  ShieldCheck,
  FileText,
  Settings,
  Building2,
  Send,
  QrCode,
  Trash2,
  Edit,
  Lock,
  SquarePen,
  CalendarDays,
  Cross,
  Compass,
};

export type IconNames = keyof typeof icons;
