<div class="flex items-center text-brand hover:underline hover:cursor-pointer active:text-brand-700" [ngClass]="{
  '!text-brand-300': visited(),
  'body-bold-3': size() === 'small',
  'body-bold-2': size() === 'medium',
  'body-bold-1': size() === 'large',
}" (click)="visited.set(true)" tappable>
  <ng-content>
  </ng-content>
  @if (withIcon()) {
    <div class="pl-1">
      <app-icon name="ExternalLink" size="16"></app-icon>
    </div>
  }
</div>
