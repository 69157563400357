@if (label() || showValue()) {
  <div class="flex {{ label() ? 'justify-between' : 'justify-end' }} mb-1">
    @if (label() || showValue()) {
      <span
        class="w-full text-brand-700"
        [ngClass]="{
          'body-regular-3': this.size() === 'small',
          'body-regular-2': this.size() === 'medium' || this.size() === 'large',
          'text-left': this.slot() === 'left',
          'text-center': this.slot() === 'center',
          'text-right': this.slot() === 'right'
        }"
        >{{ label() || (value() + '%') }}</span
      >
    }
  </div>
}
<div
  class="w-full rounded-full bg-brand-50"
  [ngClass]="{
    'h-1': this.size() === 'small',
    'h-2': this.size() === 'medium',
    'h-4': this.size() === 'large'
  }"
>
  <div
    class="rounded-full bg-brand-300"
    [ngClass]="{
      'h-1': this.size() === 'small',
      'h-2': this.size() === 'medium',
      'h-4': this.size() === 'large'
    }"
    [style]="{ width: value() + '%' }"
  ></div>
</div>
