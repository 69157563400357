import { enableProdMode, importProvidersFrom, LOCALE_ID, APP_INITIALIZER, ErrorHandler } from '@angular/core';

import { bootstrapApplication } from '@angular/platform-browser';
import { RouteReuseStrategy, provideRouter, Router } from '@angular/router';
import { IonicRouteStrategy, provideIonicAngular } from '@ionic/angular/standalone';
import * as SentryAngular from '@sentry/angular-ivy';

import { routes } from './app/app.routes';
import { AppComponent } from './app/app.component';
import { environment } from './environments/environment';
import { LucideAngularModule } from 'lucide-angular';
import { icons } from './lucide-icon';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import '@angular/common/locales/global/fr';
import { provideHttpClient, withInterceptors } from '@angular/common/http';
import { refreshTokenInterceptor } from './app/interceptors/refresh-token.interceptor';
import { setTokenInterceptor } from './app/interceptors/set-token.interceptor';
import { provideToastr } from 'ngx-toastr';
import { ToastComponent } from './components/archimed/toast/toast.component';

import { defineCustomElements } from '@ionic/pwa-elements/loader';
import { missingPermissionInterceptor } from './app/interceptors/missing-permission/missing-permission.interceptor';
// Call the element loader before the bootstrapModule/bootstrapApplication call
defineCustomElements(window);

if (environment.production) {
  enableProdMode();
}

bootstrapApplication(AppComponent, {
  providers: [
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    { provide: LOCALE_ID, useValue: 'fr-FR' },
    provideIonicAngular({ useSetInputAPI: true }),
    provideRouter(routes),
    provideHttpClient(withInterceptors([setTokenInterceptor, refreshTokenInterceptor, missingPermissionInterceptor])),
    importProvidersFrom(LucideAngularModule.pick(icons)), // import icons
    provideAnimationsAsync(),
    provideToastr({ toastComponent: ToastComponent, tapToDismiss: false }),
    { provide: ErrorHandler, useValue: SentryAngular.createErrorHandler() },
    { provide: SentryAngular.TraceService, deps: [Router] },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [SentryAngular.TraceService],
      multi: true,
    },
  ],
});
